import React, { useEffect, useState, useRef, useContext } from 'react';
import { useMsal } from '@azure/msal-react';
import { db } from '../../firebaseConfig';
import { doc, getDoc, writeBatch, getDocs, collection, query, where } from 'firebase/firestore';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import './Home.css'; 
import NavBar from '../Navbar';
import { Navigate } from 'react-router-dom';
import Loading from '../Loading';
import { AuthContext } from '../AuthContext';

function Home() {
  const { accounts } = useMsal();
  const [modulesData, setModulesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showArrows, setShowArrows] = useState(false);
  const carouselRef = useRef(null);
  const [userInformations, setUserInformations] = useState();
  const [active, setActive] = useState(null); 
  const { authorized, userEmailFromLogin } = useContext(AuthContext);
  const [userCreated, setUserCreated] = useState(false);

  const customStyles = {
    navbar: {
      backgroundColor: 'white',
    },
    navbarText: {
      color: 'black',
    },
    userInitialNavbar: {
      backgroundColor: '#004981',
    },
    userEllipse: {
      backgroundColor: '#0067FF',
    },    
    userRectangle: {
      backgroundColor: '#0067FF',
    },
    arrowIcon: {
      color: 'white',
    },
    dropdownMenu: {
      backgroundColor: '#0067FF',
    },
    dropdownItem: {
      color: 'white',
    },
    backgroundRectangleNavbar: {
      backgroundColor: '#0067FF',
    },
    dropdownDivider: {
      backgroundColor: 'white',
    },
    moduleEllipse: {
      backgroundColor: '#0067FF'    
    },
    carouselInner: {
      justifyContent: 'flex-start',
    }
  };

  useEffect(() => {
    const fetchModulesData = async () => {
      try {
        const email = accounts.length > 0
          ? accounts[0].idTokenClaims?.email || accounts[0]?.username
          : authorized ? userEmailFromLogin : null;
    
        if (!email) {
          setLoading(false);
          return;
        }
    
        const domain = email.split('@')[1];
        if (!domain) {
          setLoading(false);
          return;
        }
    
        const userDomainRef = doc(db, 'regional_enterprises', domain);
        const userDomainDoc = await getDoc(userDomainRef);
    
        let userDoc = null;
        let subsidiaries = [];
    
        if (userDomainDoc.exists()) {
          console.log('userDomainDoc', userDomainDoc);
          const domainData = userDomainDoc.data();
          subsidiaries = domainData.subsidiary || [];
          const usersMap = domainData.users;
    
          if (usersMap) {
            userDoc = usersMap[email];
          }
        }
        
        // Se o usuário não for encontrado no domínio, buscar em 'other_users'
        if (!userDoc) {

          const otherUsersRef = doc(db, 'regional_enterprises', 'other_users');
          const otherUsersDoc = await getDoc(otherUsersRef);
  
          if (otherUsersDoc.exists()) {
            const otherUsersMap = otherUsersDoc.data().users;
  
            if (otherUsersMap) {
              userDoc = otherUsersMap[email];
            }
          }
        }
        if (userDoc) {
          setUserInformations(userDoc);
  
          // Verifica se o usuário está ativo
          setActive(userDoc.isActive ?? false);
  
          // Carregar os dados dos módulos acessíveis
          const modules = userDoc.accessibleModules || [];
          const modulePromises = modules.map(ref => getDoc(ref).then(doc => doc.data()));
          const modulesData = await Promise.all(modulePromises);
  
          setModulesData(modulesData.filter(module => module.isActive)); // Filtra os módulos ativos
        } 
        
        if (!userDoc && userDomainDoc.exists()) {

            // Carregar referências de módulos visíveis para todos
            const loadVisibleModules = async () => {
            const modulesRef = collection(db, 'modules');
            const visibleQuery = query(modulesRef, where('isVisibleForAll', '==', true));
            const visibleSnapshot = await getDocs(visibleQuery);
            return visibleSnapshot.docs.map((doc) => doc.ref); // Retorna apenas as referências dos módulos
          };
        
          const visibleModules = await loadVisibleModules();


          const nameComplete = email.split('@')[0];
          const name = nameComplete.split('.').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
          const role = 'user';
          const isActive = true;
    
          const newUser = {
            name,
            email,
            role,
            isActive,
            accessibleModules: visibleModules || [],
            subsidiaries,
          };
    
          // Iniciando o batch
          const batch = writeBatch(db);
        
          batch.set(userDomainRef, {
            users: {[email]: newUser},
          }, { merge: true });
    
          // Comitando o batch
          await batch.commit();
          setUserCreated(true);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    
  
    fetchModulesData();
  }, [accounts, db, authorized, userEmailFromLogin]);
  

  useEffect(() => {
    const checkArrowsVisibility = () => {
      if (carouselRef.current) {
        const containerWidth = carouselRef.current.clientWidth;
        const scrollWidth = carouselRef.current.scrollWidth;
        setShowArrows(scrollWidth > containerWidth);
      }
    };

    checkArrowsVisibility();
    window.addEventListener('resize', checkArrowsVisibility);

    return () => {
      window.removeEventListener('resize', checkArrowsVisibility);
    };
  }, [modulesData]);



  const scrollCarousel = (direction) => {
    if (carouselRef.current) {
      const scrollAmount = 300; // Quantidade de rolagem
      const scrollDistance = direction === 'left' ? -scrollAmount : scrollAmount;
      carouselRef.current.scrollLeft += scrollDistance;
    }
  };

  if (loading) {
    return <Loading />
  }


  // Função para garantir que o link comece com http:// ou https://
  const ensureProtocol = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };
  // Verifica se o usuário está ativo. Se não estiver, redireciona para a página Unauthorized
 
  if (userCreated) {
    return <Navigate to="/success" />;
  }


  if (!active) {
    return <Navigate to="/unauthorized" />;
  }



  return (

    <div>
      <NavBar 
        userInformations={userInformations} 
        customStylesNavbar={customStyles} 
        navbarText={'Hello, '} 
        pageMode={'corporate'}
      />

      <div className="main-home">
        <div className='main-container-home'>
          <div>
            <div className='text-container-homepage'>
                        <p className="text1">Have everything at your fingertips: </p>
                        <p className="text2">tools, profiles, projects, and more.</p>
                      </div>
                      
                      {/* Botão de rolagem para a esquerda */}
                      {showArrows && (
                        <button
                          onClick={() => scrollCarousel('left')}
                          style={{ 
                            position: 'absolute', 
                            left: '0', 
                            zIndex: 2, 
                            backgroundColor: 'white', 
                            border: 'none', 
                            cursor: 'pointer',
                            padding: '10px',
                          }}
                        >
                          <FaChevronLeft size={30} color="#5ABEFE" />
                        </button>
                      )}

                  {/* Contêiner do carrossel */}
                  <div className="carouselOuter">
                  {/* Contêiner do carrossel */}
                  <div ref={carouselRef} className="carouselInner" style={customStyles.carouselInner}>
                    {modulesData.length > 0 && (
                      <div className="gridContainer">
                        {modulesData.map((moduleData, index) => {
                          return (
                            <a
                              key={index}
                              href={ensureProtocol(moduleData.pageURL)}
                              target="_blank"
                              rel="noopener noreferrer"
                              className='homepage-card'
                            >
                      <div className="icon-container">
                        <div className="moduleEllipse" style={customStyles.moduleEllipse}>
                          {moduleData.pageIconUrl ? (
                            <span
                            className="module-icon"
                            style={{
                              display: 'inline-block', // Permite que o span contenha o SVG com tamanho ajustável
                              width: '60%', // Ajusta a largura à elipse
                              height: '60%', // Ajusta a altura à elipse
                              background: `url(${moduleData.pageIconUrl}) no-repeat center`,
                              backgroundSize: 'contain', // Ajusta a proporção da imagem
                              color: 'white',
                            }}
                            aria-label="Ícone do módulo" // Acessibilidade
                          ></span>
                          
                          ) : (
                            <span className="icon-style">Icone padrão</span> // Texto ou ícone padrão, caso a URL esteja ausente
                          )}
                        </div>
                      </div>

                  {/* Nome do módulo */}
                  <div className="text-style-card-homepage">{moduleData.pageName}</div>
                  
                  {/* Descrição do módulo */}
                  <div className="description-homepage">{moduleData.description}</div>
                            </a>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  </div>

                      {/* Botão de rolagem para a direita */}
                      {showArrows && (
                        <button
                          onClick={() => scrollCarousel('right')}
                          style={{ 
                            position: 'absolute', 
                            right: '0', 
                            zIndex: 2, 
                            backgroundColor: 'white', 
                            border: 'none', 
                            cursor: 'pointer',
                            padding: '10px',
                          }}
                        >
                          <FaChevronRight size={30} color="#5ABEFE" />
                        </button>
                      )}
          </div>
          
        </div>
      
      <div className='orange-footer-homepage'></div>
      </div>
        </div>
        
  );
}

export default Home;
