import React, { useState, useEffect }from 'react';
import { getDocs, collection } from 'firebase/firestore';
import Select from 'react-select';
import { db } from '../../../firebaseConfig';
import './ModulesAdmin.css';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

function AddModuleModal({ onClose, onSave, isSaving, userRole }) {
  const [pageName, setPageName] = useState('');
  const [pageURL, setPageURL] = useState('');
  const [status, setStatus] = useState('active');
  const [icon, setIcon] = useState('');
  const [description, setDescription] = useState('');
  const [isVisibleForAll, setIsVisibleForAll] = useState(false);
  const [subsidiaries, setSubsidiaries] = useState([]); 
  const [selectedSubsidiaries, setSelectedSubsidiaries] = useState(module.subsidiaries || []);
  const [isUploading, setIsUploading] = useState(false); 

  useEffect(() => {
    const fetchSubsidiaries = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'subsidiary'));
        const subsidiariesList = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            value: doc.id,
            label: data.name || 'No Name', // Define um valor de fallback
          };
        });
        setSubsidiaries(subsidiariesList);
      } catch (error) {
      }
    };

    fetchSubsidiaries();
  }, []);


  const handleIconUpload = async (file) => {
    if (!file) {
      return;
    }
  
    // Verifica se o arquivo é uma imagem (opcional, mas recomendado)
    if (!file.type.startsWith("image/")) {
      return;
    }
  
    // Exemplo: limite de 5MB
    const maxSize = 5 * 1024 * 1024; 
    if (file.size > maxSize) {
      return;
    }
  
    try {
      setIsUploading(true); // Inicia o estado de carregamento
  
      const storage = getStorage(); // Obtém o Storage do Firebase
      const storageRef = ref(storage, `modulesIcons/${file.name}`); // Cria referência para o arquivo
  
      // Envia o arquivo para o Firebase Storage
      await uploadBytes(storageRef, file);
  
      // Obtém a URL pública do arquivo
      const fileURL = await getDownloadURL(storageRef);
  
      setIcon(fileURL);
    } catch (error) {

    } finally {
      setIsUploading(false); // Finaliza o estado de carregamento
    }
  };

  const handleSave = () => {
    const subsidiariesArray = selectedSubsidiaries.map(sub => sub.value);
    console.log('subsidiaries: ', subsidiariesArray);
    onSave({
      ...module,
      pageName,
      pageURL,
      isActive: status === 'active',
      icon: icon,
      description: description,
      isVisibleForAll: isVisibleForAll,
      subsidiaries: subsidiariesArray
    });
  };

  const handleSubsidiaryChange = (selectedOptions) => {
    setSelectedSubsidiaries(selectedOptions);
  };

  return (
    <div className="modal">
      <div className="modal-content-modules">
        <h2>Add New Module</h2>
        <label>
          <p>Name:</p>
          <input
            className='input-modules'
            type="text"
            value={pageName}
            onChange={(e) => setPageName(e.target.value)}
          />
        </label>
        <label>
          <p>Description:</p>
            <input
            className='input-modules'
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          
        </label>
        <label>
          <p>Link:</p>
            <input
            className='input-modules'
            type="text"
            value={pageURL}
            onChange={(e) => setPageURL(e.target.value)}
          />
          
        </label>
        <label>
          <p>Icon:</p>
          <input
            className="input-modules"
            type="file"
            accept="image/*"
            onChange={(e) => handleIconUpload(e.target.files[0])} // Sends the selected file
            disabled={isUploading} // Disables input during ongoing upload
          />
        </label>
        <label>
          <p>Status:</p>
          <select
            className="status-dropdown"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </label>

        {userRole === 'admin_global' && (
          <>
            <label>
              <p>Subsidiary:</p>
              <Select
                isMulti
                options={subsidiaries}
                value={selectedSubsidiaries}
                onChange={handleSubsidiaryChange}
                className="subsidiary-select-dropdown"
                placeholder="Choose subsidiaries..."
              />
            </label>

            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={isVisibleForAll}
                onChange={(e) => setIsVisibleForAll(e.target.checked)}
              />
              <span>Visible for all users</span>
            </label>
          </>
        )}
        <div className="modal-actions">
          <button onClick={onClose}>Cancel</button>
          <button onClick={handleSave} className='save-buttom-modules'>Save</button>
        </div>

        {isSaving && (
          <div className="loading-overlay">
            <div className="spinner"></div>
            <p>Saving changes, please wait...</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default AddModuleModal;