import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../../firebaseConfig'; // Importando a configuração do Firebase para criar referência
import '../AddUserModal/AddUserModal.css';
import { FaSearch } from 'react-icons/fa';

function RemoveUserModal({ module, onClose, onRemove, usersData, userRole, userInformations }) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectAll, setSelectAll] = useState(false); // Estado para o checkbox de selecionar todos
  const [permittedDomains, setPermittedDomains] = useState([]);
  const [subsidiariesUsers, setSubsidiariesUsers] = useState([]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCheckboxChange = (email) => {
    if (selectedUsers.includes(email)) {
      setSelectedUsers(selectedUsers.filter((user) => user !== email));
    } else {
      setSelectedUsers([...selectedUsers, email]);
    }
  };

  // Função para selecionar ou desmarcar todos os usuários
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedUsers([]); // Desmarcar todos
    } else {
      // Selecionar todos os usuários filtrados
      const allEmails = filteredUsers.map((user) => user.email);
      setSelectedUsers(allEmails);
    }
    setSelectAll(!selectAll); // Alterna o estado de selecionar todos
  };

  useEffect(() => {
    const calculatePermittedDomains = async () => {
      const userSubsidiaries = Array.isArray(userInformations.subsidiaries)
        ? userInformations.subsidiaries
        : [userInformations.subsidiaries];
  
      let allDomains = [];
      for (const subsidiaryId of userSubsidiaries) {
        if (typeof subsidiaryId === 'string' && subsidiaryId) {
          const subsidiaryRef = await getDoc(doc(db, 'subsidiary', subsidiaryId));
          const domains = subsidiaryRef.data().domains;
          if (domains && Array.isArray(domains)) {
            allDomains = allDomains.concat(domains);
          }
        }
      }
      setPermittedDomains([...new Set(allDomains)]); // Remove duplicatas e atualiza o estado
    };
  
    calculatePermittedDomains();
  }, [userInformations.subsidiaries]); // Recalcula sempre que as subsidiárias mudarem

  useEffect(() => {
    const fetchSubsidiariesUsers = async () => {
      const usersSubsidiaries = Array.isArray(userInformations.subsidiaries)
        ? userInformations.subsidiaries
        : [userInformations.subsidiaries]; // Subsidiárias permitidas para o usuário atual
      
      try {
        // Referência ao documento único
        const userRef = doc(db, 'regional_enterprises', 'other_users');
        const userDoc = await getDoc(userRef);
    
        if (userDoc.exists()) {
          const users = userDoc.data().users || {}; 
    
          // Filtra os usuários com base nas subsidiárias
          const subsidiariesUsers = Object.values(users).filter((user) =>
            user.subsidiaries?.some((subsidiary) => usersSubsidiaries.includes(subsidiary))
          );
          
          setSubsidiariesUsers(subsidiariesUsers); // Atualiza o estado com os usuários filtrados
        } else {
        }
      } catch (error) {
      }
    };

    fetchSubsidiariesUsers();
  }, [userInformations.subsidiaries]);
  

  // Filtra usuários com base nos permittedDomains e subsidiariesUsers
  const filteredUsers = usersData
    .filter((user) => {
      const isAlreadyInModule = user.accessibleModules?.some((accessibleModule) => accessibleModule.id === module.id);

      if (!isAlreadyInModule) {
        return false; // Excluir usuários que já estão no módulo
      }

      if (userRole === 'admin_global') {
        return true; // Admin global vê todos os usuários que ainda não estão no módulo
      }

      const userDomain = user.email.substring(user.email.lastIndexOf('@') + 1); // Extrai o domínio do e-mail do usuário
      const isInPermittedDomains = permittedDomains.includes(userDomain);
      const isInSubsidiariesUsers = subsidiariesUsers.some((subsidiaryUser) => subsidiaryUser.email === user.email);

      return isInPermittedDomains || isInSubsidiariesUsers; // Admin local vê usuários dos domínios ou subsidiárias permitidas
    })
    .filter((user) => {
      // Aplicar o filtro de busca por nome ou email
      return (
        user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

  const handleSave = () => {
    // Aqui criamos as referências corretas para o módulo no Firestore
    const moduleRef = doc(db, 'modules', module.id); // Cria referência ao módulo no Firestore
    onRemove(selectedUsers, moduleRef); // Passa a lista de usuários selecionados e a referência do módulo para o onRemove
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Remove Users to {module.pageName}</h2>
        <div className='modal-search-input'>
          <div className="search-bar-modal">
            <FaSearch className="search-icon-modal" />
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              className="search-input-modal"
            />
          </div>          
        </div>

        {/* Checkbox de selecionar todos */}
        <div className="select-all-container">
          <label>
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            Select All
          </label>
        </div>

        <div className="user-list-container"> 
          {filteredUsers.map((user) => (
            <label key={user.email} className="user-card">
              <input
                type="checkbox"
                checked={selectedUsers.includes(user.email)}
                onChange={() => handleCheckboxChange(user.email)}
              />
              {user.name || user.email} ({user.email})
            </label>
          ))}
        </div>
        <div className="modal-actions">
          <button onClick={onClose}>Cancel</button>
          <button onClick={handleSave}>Remove</button>
        </div>
      </div>
    </div>
  );
}

export default RemoveUserModal;