import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../msalConfig';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import './Login.css';
import rec1 from '../../assets/rec1.png';
import rec2 from '../../assets/rec2.png';
import rec3 from '../../assets/rec3.png';
import rec4 from '../../assets/rec4.png';
import rec5 from '../../assets/rec5.png';
import core_logo_blue from '../../assets/core_logo_blue.svg'; // Logo principal
import msLogo from '../../assets/msLogo.png'; // Logo para o carrossel
import logoTPF from '../../assets/logo_tpf_white.svg' // Logo para o carrossel
import { useState, useContext } from 'react';
import EmailIcon from '../../assets/email_icon.png';
import PasswordIcon from '../../assets/password_icon.png';
import { signInWithEmailAndPassword, sendPasswordResetEmail, fetchSignInMethodsForEmail } from 'firebase/auth';
import { auth, db } from '../../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import { Navigate } from 'react-router-dom';


function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate(); 
  const { instance } = useMsal();
  const { setAuthorized, authorized, setUserEmail, userEmailFromLogin } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState('');


  const slides =  [
    { src: rec1, author: '© Óscar André' },
    { src: rec2, author: '© Bárbara Vilar' },
    { src: rec3, author: '© Luíza Cavalcanti' },
    { src: rec4, author: '© Jailson Ramos Vitório Messias' },
    { src: rec5, author: '© Marco Salgueiro Lima' },
  ];

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((error) => {
    });
  };

  const handleLoginButton = async (e) => {
    e.preventDefault();
  
    try {
      // Logar o usuário com email e senha
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const userEmail = userCredential.user.email;
        setUserEmail(userEmail);
  
      // Buscar o documento do domínio no Firestore
      const userDomainRef = doc(db, 'regional_enterprises', 'other_users');
      const userDomainDoc = await getDoc(userDomainRef);
  
      if (!userDomainDoc.exists()) {
        throw new Error('Documento do domínio não encontrado no Firestore.');
      }
  
      const usersMap = userDomainDoc.data().users;
      if (!usersMap) {
        throw new Error('Mapa de usuários vazio ou inexistente.');
      }
  
      // Verificar se o email do usuário logado está no mapa de usuários
      const userDoc = usersMap[userEmail];
      if (!userDoc) {
        throw new Error(`Usuário ${userEmail} não encontrado no mapa de usuários.`);
      }

      if (userDoc.isDefaultPassword) {
          // Enviar email de redefinição de senha
        await sendPasswordResetEmail(auth, userEmail);

        navigate('/welcome');

        usersMap[userEmail] = {
          ...usersMap[userEmail],
          isDefaultPassword: false,
        };
  
        // Atualizar o documento no Firestore
        await updateDoc(userDomainRef, { users: usersMap });
        
        return;
      }

      setAuthorized(true);
      navigate('/'); 
  
    } catch (error) {
      if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
        setErrorMessage('Incorrect email or password. Please try again.');
      } else {
        setErrorMessage('Incorrect email or password. Please try again.');
      }
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setErrorMessage('Please enter your email address before resetting your password.');
      return;
    }
  
    try {
      // Enviar email de redefinição de senha
      await sendPasswordResetEmail(auth, email);
      setErrorMessage('Password reset email sent successfully. Please check your inbox.');
    } catch (error) {
  
      // Gerenciar diferentes códigos de erro retornados pelo Firebase
      switch (error.code) {
        case 'auth/invalid-email':
          setErrorMessage('The email address is invalid. Please enter a valid email.');
          break;
        case 'auth/user-not-found':
          setErrorMessage('No account found with this email address.');
          break;
        default:
          setErrorMessage('An unexpected error occurred. Please try again later.');
      }
    }
  };
  
  


  return (
    <div className="login-container">
      <div className="image-carousel">
      <Swiper
        slidesPerView={1}
        loop={true}
        autoplay={{ delay: 3500, disableOnInteraction: false }}
        speed={1000}
        modules={[Autoplay]}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="slide-container">
              <img src={slide.src} alt={`Slide ${index + 1}`} />
              <p className="image-author">{slide.author}</p> {/* Nome do autor */}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <img src={logoTPF} alt="TPF Logo" className="carousel-logo" />

        </div>
        
        {/*SIDE B*/}
        <div className='sideb-container'>
          <div className='sideb'>

            <div className="form-container">
              <div className="centered-container">
                  <div>
                      <div>
                          <img src={core_logo_blue} className="logo-login" alt='Logo do Hub TPF'/>

                          <h3 className="welcome-title">Log in to your account</h3>
                          
                      </div>
                  </div>
                  <div className='forms'>
                    <form onSubmit={handleLoginButton}>
                      <div className="input-container">
                        <input
                          type="email"
                          autoComplete="email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder='E-mail'
                          className="input-field"
                        />
                        <img src={EmailIcon} alt="Email Icon" className="input-icon" />
                      </div>

                      <div className="input-container">
                        <input
                          type="password"
                          autoComplete="current-password"
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder='Password'
                          className="input-field"
                        />
                        <img src={PasswordIcon} alt="Password Icon" className="input-icon" />
                      </div>

                      {errorMessage && (
                        <div className="error-popup">
                          <div className="error-content">
                            <span className="error-text"><strong>{errorMessage}</strong></span>
                            <button onClick={() => setErrorMessage('')} className="close-button">Close</button>
                          </div>
                        </div>
                      )}

                      <div className='form-footer'>
                        <label className="checkbox-container">
                          <input type="checkbox" />
                          <span>Remember me</span>
                        </label>

                        <p>
                        <button
                          type="button"
                          onClick={() => handleForgotPassword()}
                          className="forgot-password-link"
                        >
                          Forgot password?
                        </button>
                      </p>

                      </div>

                      <div>
                        <button
                          type="submit"
                          className="primary-button"
                          onSubmit={handleLoginButton}
                        >
                          Login
                        </button>
                      </div>
                    </form>
                  </div>


                  <div className='separator'>
                      <div className='separator-line'></div>
                      <div className='separator-text'>or</div>
                      <div className='separator-line'></div>
                  </div>
                  <button
                      className='google-button'
                      onClick={handleLogin}
                  >
                      <img src={msLogo} alt="Outlook Icon" className='outlook-icon' /> 
                      Log in with Microsoft
                  </button>
              </div>
            </div>

            <div className='orange-divider-login'></div>
          </div>
            
        </div>

      </div>
  );
}

export default Login;
