import React, { useEffect, useState, useContext } from 'react';
import { MsalProvider, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { msalInstance } from '../msalConfig';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import Login from './Login';
import Unauthorized from './Unauthorized';
import Home from './Home/';
import AdminPage from './AdminPage/AdminPage';
import EditModulePage from './AdminPage/Modules Admin/EditModulePage';
import Loading from './Loading';
import Success from './Success';
import Welcome from './Welcome';
import { AuthContext, AuthProvider } from './AuthContext';



function AppContent() {
  const { authorized, userEmailFromLogin } = useContext(AuthContext);
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [userRole, setUserRole] = useState(null); 
  const [loading, setLoading] = useState(true); 
  const [userInformations, setUserInformations] = useState(null);
  const [error, setError] = useState(null); 


  // Redirecionamento e autenticação
  useEffect(() => {
    const handleRedirect = async () => {
      try {
        await instance.initialize(); 
        const response = await instance.handleRedirectPromise();
  
        if (response) {
          window.location.href = '/';
        }
      } catch (error) {
        setError('Erro de autenticação.'); 
      } finally {
        setLoading(false); 
      }
    };
  
    if (isAuthenticated) {
      // Se o usuário estiver autenticado via MSAL, lidamos com o redirecionamento
      handleRedirect();
    } else if (authorized) {
      setLoading(false);
    } else {
      // Se não estiver autenticado de nenhuma forma, iniciar redirecionamento MSAL (opcional, dependendo da sua lógica)
      handleRedirect();
    }
  }, [instance, isAuthenticated, authorized]);
  

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        let userEmail;
  
        // Define o email do usuário dependendo da origem da autenticação
        if (isAuthenticated && accounts.length > 0) {
          userEmail = accounts[0].username;
        } else if (authorized) {
          userEmail = userEmailFromLogin;
        }
  
        if (!userEmail) {
          setUserRole('user');
          setLoading(false);
          return;
        }
  
        const domain = userEmail.split('@')[1];
        
        // Tenta buscar o usuário pelo domínio na coleção `regional_enterprises`
        const userDomainRef = doc(db, 'regional_enterprises', domain); 
        const userDomainDoc = await getDoc(userDomainRef);
  
        if (userDomainDoc.exists()) {
          const usersMap = userDomainDoc.data().users;
          const userDoc = usersMap ? usersMap[userEmail] : null;
  
          if (userDoc) {
            setUserInformations(userDoc);
            setUserRole(userDoc.role || 'user');
            setLoading(false);
            return;
          } else {
          }
        } else {
        }
  
        // Caso o usuário não tenha sido encontrado, buscar em `other_users`
        const otherUsersRef = doc(db, 'regional_enterprises', 'other_users');
        const otherUsersDoc = await getDoc(otherUsersRef);
  
        if (otherUsersDoc.exists()) {
          const otherUsersMap = otherUsersDoc.data().users;
          const userDoc = otherUsersMap ? otherUsersMap[userEmail] : null;
  
          if (userDoc) {
            setUserInformations(userDoc);
            setUserRole(userDoc.role || 'user');
            setLoading(false);
            return;
          } else {
          }
        } else {
        }
  
        // Se o usuário não foi encontrado em nenhum lugar
        throw new Error(`Usuário ${userEmail} não encontrado em 'regional_enterprises' ou 'other_users'.`);
      } catch (error) {
        setError('Erro ao buscar informações do usuário.');
        setUserRole('user'); // Define como 'user' se não encontrado
      } finally {
        setLoading(false);
      }
    };
  
    // Executa a função se `isAuthenticated` (MSAL) ou `authorized` (Firebase) estiverem ativos
    if (isAuthenticated || authorized) {
      fetchUserRole();
    }
  }, [isAuthenticated, authorized, accounts, userEmailFromLogin]);
  


  if (loading) {
    return <Loading />
  }


  return (
    <>
      <Routes>
        <Route path="/" element={(isAuthenticated || authorized) ? <Home /> : <Navigate to="/login" />} />
        <Route
          path="/admin"
          element={(isAuthenticated || authorized) ? (
            userRole === 'admin_global' || userRole === 'admin_local'
              ? <AdminPage userRole={userRole} userInformations={userInformations} />
              : <Navigate to="/unauthorized" />
          ) : <Navigate to="/login" />}
        />
        <Route
          path="/admin/edit-module/:moduleId"
          element={(isAuthenticated || authorized) ? (
            userRole === 'admin_global' || userRole === 'admin_local'
              ? <EditModulePage />
              : <Navigate to="/unauthorized" />
          ) : <Navigate to="/login" />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path='/success' element={< Success/>}/>
        <Route path='/welcome' element={<Welcome />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </>
  );
}

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <AuthProvider>
      <Router>
        <AppContent />
      </Router>
      </AuthProvider>
    </MsalProvider>
  );
}

export default App;
