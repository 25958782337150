import React from 'react';
import './LoadingSpinner.css'; // O CSS do spinner

function LoadingSpinner() {
  return (
      <div className="spinner"></div>    
  )
}

export default LoadingSpinner;
