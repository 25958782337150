import React from 'react';
import './Loading.css';

function Loading() {
  return (
    <div className="loading-container-login">
      <div className="spinner-login"></div>
      <p className="loading-text-login">Loading...</p>
    </div>
  );
}

export default Loading;
