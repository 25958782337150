import React from 'react';
import xCircle from '../../../../assets/x-circle.svg';
import './UserCard.css';

function UserCard({ user, onRemove }) {
  return (
    <div className="user-card-moduleCard">
      <span>{user.name || user.email} ({user.email})</span>
      <button className='remove-user-button-usercard' onClick={onRemove}>
        <img src={xCircle} alt="Remove user" className='remove-user-icon' />
      </button>
    </div>
  );
}

export default UserCard;
