import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { db } from '../../../firebaseConfig';
import { doc, getDoc, updateDoc, writeBatch, getDocs, collection, query, where } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword  } from "firebase/auth";
import * as XLSX from 'xlsx'; // Importar a biblioteca XLSX
import './UserManagement.css';
import '../User Admin/AddUserModal/AddUserModal.css';
import EditUserModal from './editModal';
import { FaSearch, FaPlus, FaEdit, FaSyncAlt } from 'react-icons/fa';
import Swal from 'sweetalert2';

function UserManagement({ userRole, accounts, userInformations }) {
  const [usersData, setUsersData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');  
  const [editingUser, setEditingUser] = useState(null); // Para controlar o popup de edição
  const [editedName, setEditedName] = useState("");
  const [loading, setLoading] = useState(false);
  const [permittedDomains, setPermittedDomains] = useState([]);
  const [subsidiariesUsers, setSubsidiariesUsers] = useState([]);
  const [availableSubsidiaries, setAvailableSubsidiaries] = useState([]);
  const auth = getAuth();

  // Função para obter o domínio do usuário atual
  const getUserDomain = useCallback(() => {
    const userEmail = accounts[0].username;
    const domain = userEmail.substring(userEmail.lastIndexOf('@') + 1);
    return domain;
  }, [accounts]);

  const sortUsersAlphabetically = useCallback((users) => {
    return users.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const combinedUsers = useMemo(() => {
    const usersMap = new Map();
  
    if (userRole === 'admin_global') {
      usersData.forEach((user) => {
        const key = `${user.email}-${user.domain}`;
        usersMap.set(key, user);
      });
    } else {
      usersData.forEach((user) => {
        const key = `${user.email}-${user.domain}`;
        usersMap.set(key, user);
      });
  
      subsidiariesUsers.forEach((subsidiaryUser) => {
        const key = `${subsidiaryUser.email}-${subsidiaryUser.domain}`;
        if (!usersMap.has(key)) {
          usersMap.set(key, subsidiaryUser);
        }
      });
    }
  
    // Retornar os usuários únicos
    return Array.from(usersMap.values());
  }, [usersData, subsidiariesUsers, userRole]);

  
useEffect(() => {
  const fetchSubsidiariesUsers = async () => {
    const usersSubsidiaries = Array.isArray(userInformations.subsidiaries)
      ? userInformations.subsidiaries
      : [userInformations.subsidiaries]; // Subsidiárias permitidas para o usuário atual
    
    try {
      // Referência ao documento único
      const userRef = doc(db, 'regional_enterprises', 'other_users');
      const userDoc = await getDoc(userRef);
  
      if (userDoc.exists()) {
        const users = userDoc.data().users || {}; 
  
        // Filtra os usuários com base nas subsidiárias
        const subsidiariesUsers = Object.values(users).filter((user) =>
          user.subsidiaries?.some((subsidiary) => usersSubsidiaries.includes(subsidiary))
        );
        
        setSubsidiariesUsers(subsidiariesUsers); // Atualiza o estado com os usuários filtrados
      } else {
      }
    } catch (error) {
    }
  };

  fetchSubsidiariesUsers();
}, [userInformations.subsidiaries]);


const filteredUsers = Array.from(
  new Map(
    combinedUsers
      .filter((user) => {
        if (userRole === 'admin_global') return true;

        const userDomain = user.email.substring(user.email.lastIndexOf('@') + 1);
        const isInPermittedDomains = permittedDomains.includes(userDomain);
        const isInSubsidiariesUsers = subsidiariesUsers.some(
          (subsidiaryUser) => subsidiaryUser.email === user.email
        );

        return isInPermittedDomains || isInSubsidiariesUsers;
      })
      .filter((user) => {
        return (
          user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
      .map((user) => [`${user.email}-${user.domain}`, user]) // Identificador único
  ).values() // Valores únicos do Map
);


  const loadUsersData = useCallback(async () => {
    setLoading(true); // Inicia o carregamento
    let usersList = [];
  
    // Se for admin global, carregar todos os usuários de todos os domínios
    if (userRole === 'admin_global') {
      const enterprisesSnapshot = await getDocs(collection(db, 'regional_enterprises'));
      
      // Itera sobre todas as empresas/organizações e carrega os usuários
      enterprisesSnapshot.forEach((doc) => {
        const usersMap = doc.data().users;
  
        // Adiciona os usuários de cada domínio (empresa/região) à lista de usuários
        usersList = usersList.concat(
          Object.entries(usersMap).map(([email, userData]) => ({
            email,
            domain: doc.id,  // O ID do documento é o domínio da organização
            
            ...userData,
          }))
        );
      });
    } else {
      // Se for admin local, carregar usuários apenas dos domínios permitidos
      const userSubsidiaries = Array.isArray(userInformations.subsidiaries) ? userInformations.subsidiaries : [userInformations.subsidiaries];
      let allDomains = [];

      for (const subsidiaryId of userSubsidiaries) {
        if (typeof subsidiaryId === 'string' && subsidiaryId) {
          const subsidiaryRef = await getDoc(doc(db, 'subsidiary', subsidiaryId));
      
          const domains = subsidiaryRef.data().domains;
          if (domains && Array.isArray(domains)) {
            allDomains = allDomains.concat(domains);  // Acumula os domínios
          }
        } else {
        }
      }
      const permittedDomains = [...new Set(allDomains)];
      setPermittedDomains(permittedDomains);

  
      // Itera sobre os domínios permitidos do admin local
      for (const permittedDomain of permittedDomains) {
        const userDomainRef = doc(db, 'regional_enterprises', permittedDomain);
        const userDomainDoc = await getDoc(userDomainRef);
  
        if (userDomainDoc.exists()) {
          const usersMap = userDomainDoc.data().users;
  
          // Adiciona os usuários do domínio permitido à lista de usuários
          usersList = usersList.concat(
            Object.entries(usersMap).map(([email, userData]) => ({
              email,
              domain: permittedDomain,  // O domínio é o domínio permitido
              ...userData,
            }))
          );
        }
      }
    }
  
    // Ordena e define os dados de usuários no estado
    setUsersData(sortUsersAlphabetically(usersList));
    setLoading(false); // Finaliza o carregamento
  }, [getUserDomain, userRole, userInformations.domains, sortUsersAlphabetically]);

// Load the users' data whenever `loadUsersData` or its dependencies change
useEffect(() => {
  loadUsersData();
}, [loadUsersData]);


// Função para habilitar/desabilitar um usuário
const toggleUserStatus = async (email, domain, isActive) => {
  try {
    const userDomainRef = doc(db, 'regional_enterprises', domain);
    const userDomainDoc = await getDoc(userDomainRef);

    if (userDomainDoc.exists()) {
      const usersMap = userDomainDoc.data().users;
      if (usersMap[email]) {
        usersMap[email].isActive = !isActive;

        await updateDoc(userDomainRef, { users: usersMap });

        // Atualiza localmente e reordena os usuários
        setUsersData((prevUsersData) =>
          sortUsersAlphabetically(
            prevUsersData.map((user) =>
              user.email === email && user.domain === domain
                ? { ...user, isActive: !isActive }
                : user
            )
          )
        );
      }
    }
  } catch (error) {
  }
};

  
  const fileInputRef = useRef(null); 

  const handleButtonClick = () => {
    fileInputRef.current.click(); 
  };

  // Função de upload, quando o arquivo for selecionado
  const handleFileUpload = async (event) => {
    const file = event.target.files[0]; // Captura o arquivo selecionado
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' }); // Lê o arquivo
      const sheetName = workbook.SheetNames[0]; // Pega o nome da primeira planilha
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet); // Converte a planilha para JSON

      setLoading(true); // Inicia o carregamento
      await processUsersFromFile(jsonData); // Processa os dados
      setLoading(false); // Finaliza o carregamento
    };

    reader.readAsBinaryString(file); // Lê o arquivo como binário
  };


  const processUsersFromFile = async (jsonData) => {
    const batchSize = 500;
    let processed = 0;
  
    // Carregar referências de módulos visíveis para todos
    const loadVisibleModules = async () => {
      const modulesRef = collection(db, 'modules');
      const visibleQuery = query(modulesRef, where('isVisibleForAll', '==', true));
      const visibleSnapshot = await getDocs(visibleQuery);
      return visibleSnapshot.docs.map((doc) => doc.ref); // Retorna apenas as referências dos módulos
    };
  
    const visibleModules = await loadVisibleModules();
  
    const canAddUser = (userRole, targetRole) => {
      if (userRole === 'admin_global' && ['admin_global', 'admin_local', 'user'].includes(targetRole)) return true;
      if (userRole === 'admin_local' && ['admin_local', 'user'].includes(targetRole)) return true;
      if (userRole === 'admin_local' && targetRole === 'admin_global') {
        return false;
      }
      return false;
    };
  
    const usersByDomain = jsonData.reduce((acc, row) => {
      const email = row.email ? row.email.trim() : null;
      if (!email) return acc;
      const domain = email.substring(email.lastIndexOf('@') + 1);
      if (!acc[domain]) acc[domain] = [];
      acc[domain].push(row);
      return acc;
    }, {});
  
    for (const domain in usersByDomain) {
      const userDomainRef = doc(db, 'regional_enterprises', domain);
      const userDomainDoc = await getDoc(userDomainRef);
  
      if (!userDomainDoc.exists()) {
        const otherDomains = doc(db, 'regional_enterprises', 'other_users');
        const otherDomainsDoc = await getDoc(otherDomains);
  
        if (!otherDomainsDoc.exists()) {
          continue;
        }
  
        let usersMap = otherDomainsDoc.data().users || {};
        const usersForDomain = usersByDomain[domain];
  
        for (let i = 0; i < usersForDomain.length; i += batchSize) {
          const batch = writeBatch(db);
          const currentBatch = usersForDomain.slice(i, i + batchSize);
  
          for (const row of currentBatch) {
            const email = row.email.trim();
            const name = row.name ? row.name.trim() : 'N/A';
            const userIsActive =
              (row.status?.toLowerCase?.() === 'active') ||
              (String(row.isActive).toLowerCase() === 'true') ||
              row.isActive === true ||
              row.isActive === 1;
            const role = row.role;
            const subsidiaries = Array.isArray(row.subsidiaries)
              ? row.subsidiaries
              : (row.subsidiaries ? row.subsidiaries.split(';').map((s) => s.trim()) : []);
            const isDefaultPassword = true;
  
            if (!canAddUser(userRole, role)) {
              continue;
            }
            registerUser(email, "P@ssw0rd123");
  
            if (!usersMap[email]) {
              usersMap[email] = {
                name,
                isActive: userIsActive,
                role,
                accessibleModules: [...visibleModules], // Adiciona os módulos visíveis para todos
                email,
                subsidiaries,
                isDefaultPassword
              };
            } else {
              // Atualiza módulos acessíveis apenas se o módulo visível para todos ainda não estiver presente
              visibleModules.forEach((module) => {
                if (!usersMap[email].accessibleModules.some((m) => m.id === module.id)) {
                  usersMap[email].accessibleModules.push(module);
                }
              });
            }
  
            usersMap[email].isActive = userIsActive;
            processed++;
          }
  
          batch.set(otherDomains, { users: usersMap }, { merge: true });
          await batch.commit();
        }
        continue;
      }
  
      let usersMap = userDomainDoc.data().users || {};
      const usersForDomain = usersByDomain[domain];
  
      for (let i = 0; i < usersForDomain.length; i += batchSize) {
        const batch = writeBatch(db);
        const currentBatch = usersForDomain.slice(i, i + batchSize);
  
        for (const row of currentBatch) {
          const email = row.email.trim();
          const name = row.name ? row.name.trim() : 'N/A';
          const userIsActive =
            (row.status?.toLowerCase?.() === 'active') ||
            (String(row.isActive).toLowerCase() === 'true') ||
            row.isActive === true ||
            row.isActive === 1;
          const role = row.role;
          const subsidiaries = Array.isArray(row.subsidiaries)
            ? row.subsidiaries
            : (row.subsidiaries ? row.subsidiaries.split(';').map((s) => s.trim()) : []);
  
          if (!canAddUser(userRole, role)) {
            continue;
          }
  
          if (!usersMap[email]) {
            usersMap[email] = {
              name,
              isActive: userIsActive,
              role,
              accessibleModules: [...visibleModules], // Adiciona os módulos visíveis para todos
              email,
              subsidiaries
            };
          } else {
            // Atualiza módulos acessíveis apenas se o módulo visível para todos ainda não estiver presente
            visibleModules.forEach((module) => {
              if (!usersMap[email].accessibleModules.some((m) => m.id === module.id)) {
                usersMap[email].accessibleModules.push(module);
              }
            });
          }
  
          usersMap[email].isActive = userIsActive;
          processed++;
        }
  
        batch.set(userDomainRef, { users: usersMap }, { merge: true });
        await batch.commit();
      }
    }
  
    // Verificação de subsidiárias para módulos
    await processSubsidiariesForModules();
  
    loadUsersData(); // Recarrega os dados de usuários após o processamento
  };
  
  // Função `processSubsidiariesForModules` adicionada
  const processSubsidiariesForModules = async () => {
    const modulesSnapshot = await getDocs(collection(db, 'modules'));
    const batch = writeBatch(db);
  
    for (const moduleDoc of modulesSnapshot.docs) {
      const moduleData = moduleDoc.data();
      const moduleRef = moduleDoc.ref;
  
      if (moduleData.subsidiaries && moduleData.subsidiaries.length > 0) {
        const uniqueDomains = new Set();
  
        for (const subsidiaryName of moduleData.subsidiaries) {
          try {
            const subsidiaryQuery = query(
              collection(db, 'subsidiary'),
              where('name', '==', subsidiaryName)
            );
            const querySnapshot = await getDocs(subsidiaryQuery);
  
            if (!querySnapshot.empty) {
              querySnapshot.forEach((subsidiaryDoc) => {
                const subsidiaryData = subsidiaryDoc.data();
  
                if (subsidiaryData.domains && Array.isArray(subsidiaryData.domains)) {
                  for (const domain of subsidiaryData.domains) {
                    uniqueDomains.add(domain);
                  }
                } else {
                }
              });
            } else {
            }
          } catch (error) {
          }
        }
  
        const domains = Array.from(uniqueDomains);
  
        for (const domain of domains) {
          const domainRef = doc(db, 'regional_enterprises', domain);
          const enterpriseDoc = await getDoc(domainRef);
  
          if (!enterpriseDoc.exists()) {
            continue;
          }
  
          const usersMap = enterpriseDoc.data().users || {};
  
          Object.keys(usersMap).forEach((email) => {
            const user = usersMap[email];
            if (!Array.isArray(user.accessibleModules)) {
              user.accessibleModules = [];
            }
            if (!user.accessibleModules.some((mod) => mod.id === moduleRef.id)) {
              user.accessibleModules.push(moduleRef);
            }
          });
  
          batch.update(domainRef, { users: usersMap });
        }
      }
    }
  
    try {
      await batch.commit();
    } catch (error) {
    }
  };
  

  const registerUser = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      // User created successfully
      const user = userCredential.user;
      return user;
    } catch (error) {
      const errorCode = error.code;
  
      switch (errorCode) {
        case "auth/email-already-in-use":
          break;
  
        case "auth/invalid-email":
          break;
  
        case "auth/operation-not-allowed":
          break;
  
        case "auth/weak-password":
          break;
  
        default:
          break;
      }
      return null; // Indicates failure to create the user
    }
  };
  

  useEffect(() => {
    loadUsersData();
  }, []); 
  

  // Função para abrir o popup de edição
  const openEditPopup = (user) => {
    setEditingUser(user);
    setEditedName(user.name); // Inicializa com o nome atual
  };

  // Função para fechar o popup
  const closeEditPopup = () => {
    setEditingUser(null);
  };

 
  const saveEditedUser = async () => {
    try {
      const userDomainRef = doc(db, 'regional_enterprises', editingUser.domain);
      const userDomainDoc = await getDoc(userDomainRef);
  
      if (userDomainDoc.exists()) {
        const usersMap = userDomainDoc.data().users;
  
        if (usersMap[editingUser.email]) {
          usersMap[editingUser.email] = {
            ...usersMap[editingUser.email],
            name: editedName,
            role: editingUser.role,
            isActive: editingUser.isActive,
            subsidiaries: editingUser.subsidiaries, // Salva as subsidiárias
          };
  
          await updateDoc(userDomainRef, { users: usersMap });
          loadUsersData();
          closeEditPopup();
        }
      }
    } catch (error) {
    }
  };


  const deleteUser = async (email, domain) => {
    const result = await Swal.fire({
      title: 'Delete Confirmation',
      text: `Are you sure you want to delete the user with email: "${email}"? This action cannot be undone.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
      customClass: {
        popup: 'custom-popup',             // Personaliza o pop-up
        title: 'custom-title',             // Personaliza o título
        confirmButton: 'custom-confirm-button', // Personaliza o botão de confirmação
        cancelButton: 'custom-cancel-button'    // Personaliza o botão de cancelamento
      }
    });
  
    if (!result.isConfirmed) {
      return; // Cancela a exclusão se o usuário não confirmar
    }
  
    setLoading(true); // Inicia o indicador de carregamento
  
    try {
      if (domain === "other_users") {
        // Se o domínio for "other_users", remove diretamente do documento
        const otherUsersRef = doc(db, 'regional_enterprises', 'other_users');
        const otherUsersDoc = await getDoc(otherUsersRef);
  
        if (otherUsersDoc.exists()) {
          const usersMap = otherUsersDoc.data().users;
  
          if (usersMap[email]) {
            delete usersMap[email];
  
            // Atualiza o Firestore
            await updateDoc(otherUsersRef, { users: usersMap });
  
            // Atualiza a lista de usuários no estado local
            setUsersData((prevUsersData) =>
              prevUsersData.filter((user) => user.email !== email)
            );
  
  
            Swal.fire({
              title: 'Deleted!',
              text: `User ${email} has been successfully removed.`,
              icon: 'success',
              confirmButtonText: 'OK',
              customClass: {
                popup: 'custom-popup',
                confirmButton: 'custom-confirm-button'
              }
            });
          } else {
            Swal.fire({
              title: 'Error',
              text: `An error occurred while deleting the user. Please try again.`,
              icon: 'error',
              confirmButtonText: 'OK',
              customClass: {
                popup: 'custom-popup',
                confirmButton: 'custom-confirm-button'
              }
            });
          }
        } else {
          Swal.fire({
            title: 'Error',
            text: `An error occurred while deleting the user. Please try again.`,
            icon: 'error',
            confirmButtonText: 'OK',
            customClass: {
              popup: 'custom-popup',
              confirmButton: 'custom-confirm-button'
            }
          });
        }
  
        return; // Finaliza a função após remover o usuário de "other_users"
      }
  
      // Caso contrário, verifica se o domínio do usuário existe no Firestore
      const userDomainRef = doc(db, 'regional_enterprises', domain);
      const userDomainDoc = await getDoc(userDomainRef);
  
      if (userDomainDoc.exists()) {
        Swal.fire({
          title: 'Cannot Delete',
          text: `User ${email} cannot be deleted because only users outside the Microsoft domain can be deleted.`,
          icon: 'info',
          confirmButtonText: 'OK',
          customClass: {
            popup: 'custom-popup',
            confirmButton: 'custom-confirm-button'
          }
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: `User ${email} cannot be deleted because only users outside the Microsoft domain can be deleted.`,
          icon: 'error',
          confirmButtonText: 'OK',
          customClass: {
            popup: 'custom-popup',
            confirmButton: 'custom-confirm-button'
          }
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while deleting the user. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
        customClass: {
          popup: 'custom-popup',
          confirmButton: 'custom-confirm-button'
        }
      });
    } finally {
      setEditingUser(null); // Fecha o popup de edição
      setLoading(false); // Finaliza o indicador de carregamento
    }
  };
  
  
  const loadAvailableSubsidiaries = async () => {
    try {
      const subsidiaryRef = collection(db, 'subsidiary');
      const snapshot = await getDocs(subsidiaryRef);
  
      // Mapeia os documentos para { id, name }
      const subsidiaries = snapshot.docs.map((doc) => ({
        id: doc.id, // ID do documento
        name: doc.data().name, // Campo 'name' do documento
      }));
  
      setAvailableSubsidiaries(subsidiaries); // Atualiza o estado
    } catch (error) {
    }
  };
  
  // UseEffect para carregar subsidiárias na montagem do componente
  useEffect(() => {
    loadAvailableSubsidiaries();
  }, []);
  

  return (
    <div className='main-container-user-management'>
      <div className="user-management-container">
        {/* Barra de pesquisa com ícones */}
        <div className="search-upload-container">
          <div className="search-bar">
            <FaSearch className="search-icon" />
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              className="search-input"
            />
          </div>
    
          {/* Botão de upload */}
          <button className="upload-button" onClick={handleButtonClick}>
            <FaPlus />
          </button>
    
          {/* Input de arquivo invisível */}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept=".xlsx"
            onChange={handleFileUpload}
          />
        </div>
    
        {/* Tabela de usuários */}
        <div className="table-container">
          {loading ? (
            <div className="loading-screen">
              <div className="spinner"></div>
              <p>Carregando usuários...</p>
            </div>
          ) : (
            <table className="user-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Subsidiary</th>
                  <th>Role</th>
                  <th>Status</th>
                  <th>Action</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user) => {
                  return (
                    <tr key={`${user.email}-${user.domain}`}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.subsidiaries?.join(', ') || '-'}</td>
                      <td>
                        {user.role === 'admin_global' && <span>Global</span>}
                        {user.role === 'admin_local' && <span>Local</span>}
                        {user.role === 'user' && <span>User</span>}
                      </td>
                      <td>
                      <span
                        style={{
                          display: 'inline-block',
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          marginRight: '8px',
                          backgroundColor: user.isActive ? 'green' : 'red',
                        }}
                      ></span>
                      {user.isActive ? 'Active' : 'Inactive'}
                    </td>
                    <td>
                    {(userRole === 'admin_global' || (() => {
                      return permittedDomains;
                    })())
                    && (
                      <button
                        className='toggle-button'
                        onClick={() => toggleUserStatus(user.email, user.domain, user.isActive)}
                      >
                        <FaSyncAlt />
                      </button>
                    )}
                  </td>
                      <td>
                        <button className="edit-button" onClick={() => openEditPopup(user)}>
                          <FaEdit />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
{editingUser && (
  <EditUserModal
    editingUser={editingUser}
    editedName={editedName}
    setEditedName={setEditedName}
    closeEditPopup={closeEditPopup}
    saveEditedUser={saveEditedUser}
    deleteUser={deleteUser}
    userRole={userRole}
    setEditingUser={setEditingUser}
    availableSubsidiaries={availableSubsidiaries}
  />
)}

        </div>
      </div>
    </div>
  );
  
}

export default UserManagement;
