import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    // "94419d9e-40f7-4eee-877a-548a2654b6da"
    // "abee6489-9be5-4de6-8860-d68bf08f9158"
    clientId: "94419d9e-40f7-4eee-877a-548a2654b6da", // Substitua pelo seu Client ID do Azure AD
    authority: "https://login.microsoftonline.com/common", // Para multi-tenant
    redirectUri: window.location.hostname === 'localhost'
    ? "http://localhost:3000/" // Redirecionamento para localhost
    : "https://core.tpf.be/", // Redirecionamento para produção
  },
  cache: {
    cacheLocation: "sessionStorage", // Use sessionStorage ou localStorage
    storeAuthStateInCookie: false, // Alterar para true se necessário para navegadores mais antigos
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (level === 'error') {
        } else if (level === 'info') {
        } else if (level === 'verbose') {
        } else if (level === 'warning') {
        }
      },
      piiLoggingEnabled: false,
      logLevel: 'info',
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  scopes: ["User.Read"], // Escopos necessários
};