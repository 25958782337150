import React from 'react';
import './SearchBar.css';
import { FaSearch } from 'react-icons/fa';

function SearchBar({ searchTerm, setSearchTerm }) {
  return (
    <div className="search-upload-bar">
      <div className="search-input-wrapper">
        <FaSearch className="search-icon-search-bar" />
        <input
          type="text"
          placeholder="Search User"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

    </div>
  );
}


export default SearchBar;
