import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { db } from '../../../../firebaseConfig';
import { doc, getDoc, updateDoc, collection, getDocs, writeBatch, setDoc  } from 'firebase/firestore';
import AddUserModal from '../AddUserModal';
import SearchBar from '../SearchBar';
import ModuleCard from '../ModuleCard';
import './UserSection.css';
import RemoveUserModal from '../RemoveUserModal';


function UsersSection({ userRole, accounts, modulesData, userInformations }) {
  const [usersData, setUsersData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [isRemovingUser, setIsRemovingUser] = useState(false);
  const [loadingModules, setLoadingModules] = useState({});
  const [permittedDomains, setPermittedDomains] = useState([]);
  const [subsidiariesUsers, setSubsidiariesUsers] = useState([]);


  // Função para ativar o carregamento de todos os módulos
  const setAllModulesLoading = (isLoading) => {
    const loadingState = modulesData.reduce((acc, module) => {
      acc[module.id] = isLoading; // Define o estado de carregamento para cada módulo
      return acc;
    }, {});
    
    setLoadingModules(loadingState); // Atualiza o estado com todos os módulos
  };

  const sortUsersAlphabetically = useCallback((users) => {
    return users.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  }, []);
  
  // Load all users from Firestore if the 'users' field exists in the documents
  const loadUsersData = async () => {
    setAllModulesLoading(true); // Ativa o carregamento de todos os módulos

    try {
      const enterprisesRef = collection(db, 'regional_enterprises');
      const querySnapshot = await getDocs(enterprisesRef);
      const allUsersList = [];

      querySnapshot.forEach((doc) => {
        const docData = doc.data();

        if (docData.users) {
          const usersMap = docData.users;

          // Convert each user entry to the desired format and add it to the list
          const usersList = Object.entries(usersMap).map(([email, userData]) => ({
            email,
            ...userData,
          }));
          allUsersList.push(...usersList);
        }
      });

      // Set the state with the complete list of users
      setUsersData(sortUsersAlphabetically(allUsersList));
    } catch (error) {
    } finally{
      setAllModulesLoading(false); // Desativa o carregamento de todos os módulos
    }
  };


  // Carrega os dados dos usuários na montagem do componente
  useEffect(() => {
    loadUsersData();
  }, []);


  useEffect(() => {
    const calculatePermittedDomains = async () => {
      const userSubsidiaries = Array.isArray(userInformations.subsidiaries)
        ? userInformations.subsidiaries
        : [userInformations.subsidiaries];
  
      let allDomains = [];
      for (const subsidiaryId of userSubsidiaries) {
        if (typeof subsidiaryId === 'string' && subsidiaryId) {
          const subsidiaryRef = await getDoc(doc(db, 'subsidiary', subsidiaryId));
          const domains = subsidiaryRef.data().domains;
          if (domains && Array.isArray(domains)) {
            allDomains = allDomains.concat(domains);
          }
        }
      }
      setPermittedDomains([...new Set(allDomains)]); // Remove duplicatas e atualiza o estado
    };
  
    calculatePermittedDomains();
  }, [userInformations.subsidiaries]);


  useEffect(() => {
    const fetchSubsidiariesUsers = async () => {
      const usersSubsidiaries = Array.isArray(userInformations.subsidiaries)
        ? userInformations.subsidiaries
        : [userInformations.subsidiaries]; // Subsidiárias permitidas para o usuário atual
      
      try {
        // Referência ao documento único
        const userRef = doc(db, 'regional_enterprises', 'other_users');
        const userDoc = await getDoc(userRef);
    
        if (userDoc.exists()) {
          const users = userDoc.data().users || {}; 
    
          // Filtra os usuários com base nas subsidiárias
          const subsidiariesUsers = Object.values(users).filter((user) =>
            user.subsidiaries?.some((subsidiary) => usersSubsidiaries.includes(subsidiary))
          );
          
          setSubsidiariesUsers(subsidiariesUsers); // Atualiza o estado com os usuários filtrados
        } else {
        }
      } catch (error) {
      }
    };
  
    fetchSubsidiariesUsers();
  }, [userInformations.subsidiaries]);
  

// Atualize filteredUsersForModule para usar subsidiariesUsers
const filteredUsersForModule = (moduleId) => {
  return usersData
    // Filtrar usuários que possuem o módulo acessível
    .filter((user) => user.accessibleModules && user.accessibleModules.some((module) => module.id === moduleId))
    // Filtrar usuários que pertencem às subsidiárias permitidas
    .filter((user) => {
      if (userRole === 'admin_global') {
        return true;
      }

      const userDomain = user.email.substring(user.email.lastIndexOf('@') + 1); // Extrai o domínio do e-mail do usuário
      const isInPermittedDomains = permittedDomains.includes(userDomain);
      const isInSubsidiariesUsers = subsidiariesUsers.some((subsidiaryUser) => subsidiaryUser.email === user.email);

      return isInPermittedDomains || isInSubsidiariesUsers; 
    })
    // Filtrar com base no termo de pesquisa
    .filter(
      (user) =>
        (user.name && user.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase()))
    );
};

  

  // Remove um usuário de um módulo
  const removeUserFromModule = async (userEmail, moduleId) => {
    try {
      setLoadingModules((prev) => ({ ...prev, [moduleId]: true }));
  
      // Extrai o domínio do usuário baseado no e-mail
      const userDomain = userEmail.substring(userEmail.lastIndexOf('@') + 1);
      const userDomainRef = doc(db, 'regional_enterprises', userDomain); // Referência ao banco de dados correto do usuário
      const userDomainDoc = await getDoc(userDomainRef);
  
      if (userDomainDoc.exists()) {
        const usersMap = userDomainDoc.data().users;
  
        if (usersMap[userEmail]) {
          // Filtra os módulos removendo o módulo correspondente ao moduleId
          const updatedModules = usersMap[userEmail].accessibleModules.filter(
            (module) => module.id !== moduleId
          );
  
          // Atualiza os módulos acessíveis do usuário
          usersMap[userEmail].accessibleModules = updatedModules;
  
          // Atualiza o banco de dados do domínio correto
          await updateDoc(userDomainRef, { users: usersMap });
  
          // Atualize o estado de usersData sem recarregar tudo
          setUsersData((prevUsersData) =>
            prevUsersData.map((user) =>
              user.email === userEmail
                ? { ...user, accessibleModules: updatedModules }
                : user
            )
          );
        } else {
        }
      } else {
        const otherUsersRef = doc(db, 'regional_enterprises', 'other_users');
        const otherUsersDoc = await getDoc(otherUsersRef);
  
        if (otherUsersDoc.exists()) {
          const otherUsersMap = otherUsersDoc.data().users;
  
          if (otherUsersMap[userEmail]) {
            // Filtra os módulos removendo o módulo correspondente ao moduleId
            const updatedModules = otherUsersMap[userEmail].accessibleModules.filter(
              (module) => module.id !== moduleId
            );
  
            // Atualiza os módulos acessíveis do usuário
            otherUsersMap[userEmail].accessibleModules = updatedModules;
  
            // Atualiza o documento other_users no banco de dados
            await updateDoc(otherUsersRef, { users: otherUsersMap });
  
            // Atualize o estado de usersData sem recarregar tudo
            setUsersData((prevUsersData) =>
              prevUsersData.map((user) =>
                user.email === userEmail
                  ? { ...user, accessibleModules: updatedModules }
                  : user
              )
            );
  
          } else {
          }
        } else {
        }
      }
    } catch (error) {
    } finally {
      setLoadingModules((prev) => ({ ...prev, [moduleId]: false }));
    }
  };
  

  // Abre o modal de remoção de usuários
  const handleRemoveUser = (module) => {
    setSelectedModule(module);
    setIsRemovingUser(true);
  };



  const handleRemoveUsersFromModule = async (selectedUsers, moduleRef) => {
    try {
      setLoadingModules((prev) => ({ ...prev, [moduleRef.id]: true }));
      setIsRemovingUser(false); // Fecha o modal de remoção
  
      // Cria um batch para agrupar todas as atualizações
      const batch = writeBatch(db);
  
      // Objeto para armazenar os dados de usuários carregados por domínio
      const userDomainsData = {};
  
      // Carregar todos os documentos regional_enterprises de uma vez
      const enterprisesSnapshot = await getDocs(collection(db, 'regional_enterprises'));
      enterprisesSnapshot.forEach((docSnapshot) => {
        userDomainsData[docSnapshot.id] = docSnapshot.data().users || {};
      });
  
      // Carregar o documento other_users uma vez
      const otherUsersRef = doc(db, 'regional_enterprises', 'other_users');
      const otherUsersDoc = await getDoc(otherUsersRef);
      const otherUsersData = otherUsersDoc.exists() ? otherUsersDoc.data().users : {};
  
      // Objeto para armazenar as atualizações para cada domínio
      const updatedUsersMap = {};
      let otherUsersUpdated = false; // Controle para identificar alterações em other_users
  
      // Processa cada usuário selecionado para remoção
      for (const email of selectedUsers) {
        const userDomain = email.substring(email.lastIndexOf('@') + 1);
  
        // Obter os dados do usuário do domínio correspondente ou de other_users
        const usersMap = userDomainsData[userDomain] || otherUsersData;
  
        if (!usersMap[email]) {
          continue; // Pula para o próximo usuário se não encontrado
        }
  
        // Atualizar `other_users` separadamente
        if (userDomainsData[userDomain]) {
          // Inicializa o mapa atualizado para o domínio se necessário
          if (!updatedUsersMap[userDomain]) {
            updatedUsersMap[userDomain] = { ...usersMap };
          }
  
          // Filtra o módulo a ser removido do usuário
          updatedUsersMap[userDomain][email].accessibleModules = updatedUsersMap[userDomain][email].accessibleModules.filter(
            (module) => module.id !== moduleRef.id
          );
        } else {
          // Atualizar diretamente em `other_users`
          otherUsersData[email].accessibleModules = otherUsersData[email].accessibleModules.filter(
            (module) => module.id !== moduleRef.id
          );
          otherUsersUpdated = true; // Indica que houve mudanças em `other_users`
        }
      }
  
      // Adiciona todas as operações de atualização ao batch
      for (const [userDomain, usersMap] of Object.entries(updatedUsersMap)) {
        const userDomainRef = doc(db, 'regional_enterprises', userDomain);
        batch.update(userDomainRef, { users: usersMap }); // Atualiza o documento do domínio
      }
  
      // Se houve alterações em `other_users`, adiciona ao batch
      if (otherUsersUpdated) {
        batch.update(otherUsersRef, { users: otherUsersData });
      }
  
      // Commit de todas as operações no batch
      await batch.commit();
  
      // Atualiza o estado de usersData localmente para refletir as mudanças
      setUsersData((prevUsersData) =>
        prevUsersData.map((user) =>
          selectedUsers.includes(user.email)
            ? {
                ...user,
                accessibleModules: user.accessibleModules.filter(
                  (module) => module.id !== moduleRef.id
                ),
              }
            : user
        )
      );
    } catch (error) {
    } finally {
      setLoadingModules((prev) => ({ ...prev, [moduleRef.id]: false }));
    }
  };
  
    
  // Manipula a adição de usuário ao módulo
  const handleAddUser = (module) => {
    setSelectedModule(module);
    setIsAddingUser(true);
  };

  
  const handleSaveUsersToModule = async (selectedUsers, moduleRef) => {
    try {
      setLoadingModules((prev) => ({ ...prev, [moduleRef.id]: true }));
      setIsAddingUser(false); // Fecha o modal
  
      const batch = writeBatch(db);
      const userDomainsData = {};
  
      // Carregar todos os documentos regional_enterprises de uma vez
      const enterprisesSnapshot = await getDocs(collection(db, 'regional_enterprises'));
      enterprisesSnapshot.forEach((docSnapshot) => {
        userDomainsData[docSnapshot.id] = docSnapshot.data().users || {};
      });
  
  
      // Carregar o documento other_users uma vez
      const otherUsersRef = doc(db, 'regional_enterprises', 'other_users');
      const otherUsersDoc = await getDoc(otherUsersRef);
      const otherUsersData = otherUsersDoc.exists() ? otherUsersDoc.data().users : {};
  
      // Objeto para armazenar as atualizações para cada domínio
      const updatedUsersMap = {};
      let otherUsersUpdated = false;
  
      for (const email of selectedUsers) {
        const userDomain = email.substring(email.lastIndexOf('@') + 1);
        const usersMap = userDomainsData[userDomain] || otherUsersData;
  
        if (!usersMap[email]) {
          
          continue; // Pula para o próximo usuário
        }
  
        // Atualizar `regional_enterprises` ou `other_users` conforme necessário
        if (userDomainsData[userDomain]) {
          // Inicializa o mapa atualizado para o domínio se necessário
          if (!updatedUsersMap[userDomain]) {
            updatedUsersMap[userDomain] = { ...usersMap };
          }
  
          if (!updatedUsersMap[userDomain][email].accessibleModules.some((module) => module.id === moduleRef.id)) {
            updatedUsersMap[userDomain][email].accessibleModules.push(moduleRef);
          }
        } else {
          // Atualizar diretamente em `other_users`
          if (!otherUsersData[email].accessibleModules.some((module) => module.id === moduleRef.id)) {
            otherUsersData[email].accessibleModules.push(moduleRef); // Adiciona o módulo
            otherUsersUpdated = true; // Indica que houve mudanças em `other_users`
          }
        }
      }
  
      // Executa todas as atualizações em batch
      for (const [userDomain, usersMap] of Object.entries(updatedUsersMap)) {
        const userDomainRef = doc(db, 'regional_enterprises', userDomain);
        batch.update(userDomainRef, { users: usersMap });
      }
  
      // Se houve alterações em `other_users`, adiciona ao batch
      if (otherUsersUpdated) {
        batch.update(otherUsersRef, { users: otherUsersData });
      }
  
      await batch.commit();
  
      setUsersData((prevUsersData) =>
        prevUsersData.map((user) =>
          selectedUsers.includes(user.email)
            ? { ...user, accessibleModules: [...user.accessibleModules, moduleRef] }
            : user
        )
      );
    } catch (error) {
    } finally {
      setLoadingModules((prev) => ({ ...prev, [moduleRef.id]: false }));
    }
  };
  

  
  const memoizedModules = useMemo(
    () =>
      modulesData.map((module) => (
        <ModuleCard
          key={module.id}
          module={module}
          users={filteredUsersForModule(module.id)}
          handleRemoveUser={removeUserFromModule}
          handleAddUser={() => handleAddUser(module)}
          handleRemoveUserModal={() => handleRemoveUser(module)}
          loading={loadingModules[module.id]}
        />
      )),
    [modulesData, usersData, loadingModules, searchTerm]
  );
  

  return (
    <div>

      {/* Barra de pesquisa e upload */}
      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

      {/* Exibe os módulos e os usuários em cada módulo */}
      <div className="modules-users-container">{memoizedModules}</div>

      {/* Modal para adicionar usuários ao módulo */}
      {isAddingUser && (
        <AddUserModal
          module={selectedModule}
          onClose={() => setIsAddingUser(false)}
          onSave={handleSaveUsersToModule}
          usersData={usersData}
          userRole={userRole}
          userInformations={userInformations}
        />
      )}

        {isRemovingUser && (
        <RemoveUserModal
          module={selectedModule}
          onClose={() => setIsRemovingUser(false)}
          onRemove={handleRemoveUsersFromModule}
          usersData={usersData} 
          userRole={userRole}
          userInformations={userInformations}
        />
      )}
    </div>
  );
}

export default UsersSection;
