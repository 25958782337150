import React from 'react';
import './Success.css';
import successImage from '../../assets/core_logo_blue.svg'; // Certifique-se de que a imagem esteja no local correto

function Success() {
  return (
    <div className="success-container">
      <img src={successImage} alt="Success" className="success-image" />
      <h1 className="success-title">You Have Been Registered Successfully!</h1>
      <p className="success-message">
        Please log in again to access your account.
      </p>
      <button className="login-button" onClick={() => window.location.href = '/login'}>
        Go to Login
      </button>
    </div>
  );
}

export default Success;
