import React from 'react';
import '../../Home/Home.css'

function ModulesSection({ modulesData, setSelectedModule, openAddModuleModal, userRole }) {
  const customStyles = {
    moduleEllipse: {
      backgroundColor: '#D3D3D3'    
    },
    carouselInner: {
      justifyContent: 'center',
    },
    espacamento: {
      height: '30px',
    }
  }
  return (
    <div>
      {userRole === 'admin_global' && (
        <button className="add-module-button" onClick={openAddModuleModal}>
          Add Module
        </button>
      )}
      <div style={customStyles.espacamento}></div>
      {/* Contêiner do carrossel */}
      <div className="carouselOuter">
        <div className="carouselInner" style={customStyles.carouselInner}>
          {modulesData.length > 0 && (
            <div className="gridContainer">
              {modulesData.map((module, index) => (
                <div
                  key={module.id}
                  className={`homepage-card ${module.isActive ? 'active' : 'inactive'}`}
                  onClick={() => setSelectedModule(module)}
                >
                {/* Ícone do módulo */}
                <div className="icon-container">
                        <div className="moduleEllipse" style={customStyles.moduleEllipse}>
                          {module.pageIconUrl ? (
                            <span
                            className="module-icon"
                            style={{
                              display: 'inline-block', // Permite que o span contenha o SVG com tamanho ajustável
                              width: '60%', // Ajusta a largura à elipse
                              height: '60%', // Ajusta a altura à elipse
                              background: `url(${module.pageIconUrl}) no-repeat center`,
                              backgroundSize: 'contain', // Ajusta a proporção da imagem
                              color: 'white',
                            }}
                            aria-label="Ícone do módulo" // Acessibilidade
                          ></span>
                          
                          ) : (
                            <span className="icon-style">Icone padrão</span> // Texto ou ícone padrão, caso a URL esteja ausente
                          )}
                        </div>
                      </div>

                  {/* Nome do módulo */}
                  <div className="text-style-card-homepage">{module.pageName}</div>
                  
                  {/* Descrição do módulo */}
                  <div className="description-homepage">{module.description}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModulesSection;
