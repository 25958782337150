import React from 'react';
import './Unauthorized.css';
import errorImage from '../../assets/core_logo_blue.svg'; // Certifique-se de colocar a imagem no local correto

function Unauthorized() {
  return (
    <div className="error-container">
      <img src={errorImage} alt="Error" className="error-image" />
      <h1 className="error-title">Oops! Access Denied</h1>
      <p className="error-message">
        It looks like your email domain is not authorized to access this application.
      </p>
      <button className="home-button" onClick={() => window.location.href = '/login'}>
        Go to Login
      </button>
    </div>
  );
}

export default Unauthorized;
