import React from 'react';
import './Welcome.css';
import welcomeImage from '../../assets/core_logo_blue.svg'; // Update path as necessary

function Welcome() {
  return (
    <div className="welcome-container">
      <img src={welcomeImage} alt="Welcome" className="welcome-image" />
      <h1 className="welcome-user-title">Welcome! Please Update Your Password</h1>
      <p className="welcome-message">
        This is your first time accessing the system, and for your security, we require you to update your default password before proceeding. Please check your email for the link to update your password and ensure a more personalized and secure experience.
      </p>
      <button className="login-button" onClick={() => window.location.href = '/login'}>
        Go to Login
      </button>
    </div>
  );
}

export default Welcome;
