import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import './ModulesAdmin.css';

function EditModulePage() {
  const { moduleId } = useParams();
  const [moduleData, setModuleData] = useState({
    pageName: '',
    pageUrl: '',
    isActive: true,
    pageIconUrl: '',
    description: ''
  });

  useEffect(() => {
    const fetchModuleData = async () => {
      const moduleRef = doc(db, 'modules', moduleId);
      const moduleDoc = await getDoc(moduleRef);

      if (moduleDoc.exists()) {
        setModuleData(moduleDoc.data());
      } else {
      }
    };

    fetchModuleData();
  }, [moduleId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setModuleData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const moduleRef = doc(db, 'modules', moduleId);
    await updateDoc(moduleRef, moduleData);
  };

  return (
    <div>
      <h2>Edit Module</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input
            type="text"
            name="pageName"
            value={moduleData.pageName}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Description:
          <input
            type="text"
            name="pageUrl"
            value={moduleData.description}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          URL:
          <input
            type="text"
            name="pageUrl"
            value={moduleData.pageUrl}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Active:
          <input
            type="checkbox"
            name="isActive"
            checked={moduleData.isActive}
            onChange={() => setModuleData(prevData => ({
              ...prevData,
              isActive: !prevData.isActive
            }))}
          />
        </label>
        <button type="submit">Save</button>
      </form>
    </div>
  );
}

export default EditModulePage;