import React, { createContext, useState, useEffect } from 'react';
import { MsalProvider, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { msalInstance } from '../msalConfig';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authorized, setAuthorized] = useState(false); // Novo estado para login direto
  const [userEmailFromLogin, setUserEmail] = useState('');
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  useEffect(() => {
    if (isAuthenticated) {
      const currentUser = instance.getActiveAccount();
      setUser(currentUser);
      setAuthorized(true);
      setUserEmail(currentUser?.username || '');
    } else {
      setUser(null);
      setAuthorized(false);
      setUserEmail('');
    }
  }, [isAuthenticated, instance]);

  const getUserDomain = () => {
    if (user) {
      return user.username.split('@')[1]; // Extrair o domínio do email
    }
    return null;
  };



  return (
    <MsalProvider instance={msalInstance}>
      <AuthContext.Provider value={{ user, authorized, setAuthorized, getUserDomain, userEmailFromLogin, setUserEmail }}>
        {children}
      </AuthContext.Provider>
    </MsalProvider>
  );
};
