import React, {useState, useEffect} from 'react';
import './ModuleCard.css'; // Importação do CSS
import UserCard from '../UserCard';
import usersIcon from '../../../../assets/users_icon.svg';
import plusIcon from '../../../../assets/plus_icon.svg';
import { FaTrash } from 'react-icons/fa';
import LoadingSpinner from '../LoadingSpinner'; // Importação do componente de loading

function ModuleCard({ module, users, handleRemoveUser, handleAddUser, handleRemoveUserModal, loading }) {
  
  return (
    <div className="module-group-card">
      <div className="module-group-header">
        <div className='title-module-card'>
          <h3 className='module-title'>{module.pageName}</h3>
        </div>
        <div className='icons-module-card'>
          <div className="user-count">
            {users.length}
            <img src={usersIcon} alt='users icon' className="user-count-icon" /> 
          </div>
          <button className="remove-user-button-modulecard" onClick={handleRemoveUserModal}>
            <FaTrash/>
          </button>
          <button className="add-user-button" onClick={handleAddUser}>
            <img src={plusIcon} alt='plus icon'/>
          </button>
        </div>
      </div>

      {/* Mostrar o indicador de carregamento se estiver carregando */}
      {loading ? (
        <div className="loading-container">
          <LoadingSpinner /> {/* Um componente de loading simples */}
        </div>
      ) : (
        <div className="module-group-users">
          {users.map((user) => (
            <UserCard 
              key={user.email} 
              user={user} 
              onRemove={() => handleRemoveUser(user.email, module.id)} 
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default ModuleCard;
